import { useState } from 'react';
import { FeaturesAddressesProps } from '../../../../modules/api-gouv/addresses.domain';
import { useAdressesGouvList } from '../../../../modules/api-gouv/useAdresses';
import { ProjectProps } from '../../../../modules/project/domain/project.entity';
import { InputSelectSearchAsync } from '../InputSelectSearchAsync';

interface InputAddressProps {
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
}

export function InputAddress({ required = true }: InputAddressProps) {
  const [search, setSearch] = useState<string>('');
  const { data, isLoading } = useAdressesGouvList({ search }, { enabled: !!search });

  return (
    <InputSelectSearchAsync<ProjectProps, FeaturesAddressesProps>
      label="Adresse"
      name="address"
      required={required}
      getOptionLabel={(option) => option?.properties?.label as string}
      getOptionValue={(option) => option?.properties?.id as unknown as string}
      data={data?.features ?? []}
      isLoading={isLoading}
      search={search}
      setSearch={setSearch}
    />
  );
}
