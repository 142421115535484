import { Dispatch, SetStateAction, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { InputSelectSearchAsync } from '.';
import { ESort } from '../../../../libs/interfaces';
import { EType } from '../../../../modules/organisation/domain/organisation.entity';
import { useOrganisationsListPaginate } from '../../../../modules/organisation/queries/useOrganisations';
import { OrganisationResponse } from '../../../../modules/organisation/responses/Organisation.response.dto';
import { EStyleSelect } from '../SelectInput';

export interface SelectOrganisationProps<FieldName> {
  label: string;
  name: keyof FieldName;
  required?: boolean;
  rules?: RegisterOptions;
  multiple?: boolean;
  isNewOne?: boolean;
  selectStyle?: EStyleSelect;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
  types?: EType[];
}
export function SelectOrganisation<FieldName>({
  label,
  required,
  name,
  rules,
  multiple,
  isNewOne,
  selectStyle,
  setOpenModal,
  types,
}: SelectOrganisationProps<FieldName>) {
  const [search, setSearch] = useState('');
  const { data, isLoading } = useOrganisationsListPaginate({
    page: 0, limit: 15, search, order: 'socialReason', sort: ESort.ASC, types: types ?? [],
  }, { keepPreviousData: false, enabled: !!search });

  return (
    <InputSelectSearchAsync<FieldName, OrganisationResponse>
      label={label}
      name={name}
      getOptionLabel={(option) => option.socialReason as string}
      getOptionValue={(option) => option.id as unknown as string}
      data={data?.data ?? []}
      isLoading={isLoading}
      search={search}
      setSearch={setSearch}
      required={required}
      rules={rules}
      multiple={multiple}
      isNewOne={isNewOne}
      selectStyle={selectStyle}
      setOpenModal={setOpenModal}
    />
  );
}

SelectOrganisation.defaultProps = {
  required: false,
  rules: {},
  multiple: false,
  isNewOne: false,
  setOpenModal: undefined,
  selectStyle: 'top',
  types: [],
};
