/* eslint-disable no-unused-vars */
import {
  Box, Modal, ModalProps, SxProps, Theme, Typography,
} from '@mui/material';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { BoxStyled } from './style';

export enum EBlacktext {
  ADD = 'add',
  UPDATE = 'update',
  REPORT = 'report'
}

// remove children from modalProps because it creates an error, so we use our children type
interface GenericModalProps extends Omit<ModalProps, 'children'> {
  children: ReactNode;
  title?: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  blackText?: EBlacktext | string;
  boxStyled?: SxProps<Theme>;
}
export function GenericModal({
  children, title, open, setOpen, blackText, style, boxStyled,
}: GenericModalProps) {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={style}
    >
      <BoxStyled sx={boxStyled}>
        {title ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              marginBottom="20px"
              component="h2"
              color="black"
              fontWeight="900"
              align="center"
            >
              {blackText === EBlacktext.ADD ? 'Ajout' : blackText === EBlacktext.UPDATE ? 'Modifier' : blackText === EBlacktext.REPORT ? 'Rapport' : blackText}
              &nbsp;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="h5"
              marginBottom="20px"
              component="h2"
              color="#FE8403"
              fontWeight="900"
              align="center"
            >
              {title}
            </Typography>
          </Box>
        ) : null}
        {children}
      </BoxStyled>
    </Modal>
  );
}

GenericModal.defaultProps = {
  blackText: EBlacktext.ADD,
  title: null,
  boxStyled: '',
};
