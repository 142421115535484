/* eslint-disable no-unused-vars */
import { GenericChoices } from '../../../libs/ddd/interfaces/base.interface';
import { PaginateProps, SearchProps, SortProps } from '../../../libs/interfaces';
import { ContactProps } from '../../contact/domain/contact.entity';
import { ProjectPropsInOrganisation } from '../../project/domain/project.entity';

export enum EOrganisationType {
  COMPANY = 'company',
  ASSOCIATION = 'association',
  COMMUNITY = 'community',
  COOWNERSHIP = 'coownership',
  OTHER = 'other',
}

export enum EType {
  ADMINISTRATOR = 'administrator',
  EXPLOITING = 'exploiting',
  PROVIDER = 'provider',
  DESIGNOFFICE = 'designOffice',
  OWNER = 'owner',
  OTHER = 'other',
}
export const ListType: GenericChoices<EType>[] = [
  {
    value: EType.ADMINISTRATOR,
    label: 'Gestionnaire',
  }, {
    value: EType.EXPLOITING,
    label: 'Exploitant',
  }, {
    value: EType.PROVIDER,
    label: 'Fournisseur d\'énergie',
  }, {
    value: EType.DESIGNOFFICE,
    label: 'Bureau d\'étude',
  }, {
    value: EType.OWNER,
    label: 'Maître d\'ouvrage',
  }, {
    value: EType.OTHER,
    label: 'Autre',
  },
];

export const ListOrganisationType: GenericChoices<EOrganisationType>[] = [
  {
    value: EOrganisationType.COMPANY,
    label: 'Entreprise',
  },
  {
    value: EOrganisationType.ASSOCIATION,
    label: 'Association',
  },
  {
    value: EOrganisationType.COMMUNITY,
    label: 'Collectivité',
  },
  {
    value: EOrganisationType.COOWNERSHIP,
    label: 'Copropriété',
  },
  {
    value: EOrganisationType.OTHER,
    label: 'Autre',
  },
];
export interface OrganisationProps {
  id: number;
  socialReason: string;
  email: string | undefined;
  city: string | undefined;
  zipcode: string | undefined;
  streetNumber: string | undefined;
  streetName: string | undefined;
  phone: string | undefined;
  type: EType | undefined;
  organisationType: EOrganisationType;
  website: string | undefined;
  contacts: ContactProps[] | undefined;
  projects: ProjectPropsInOrganisation[] | undefined;
  comment: string | undefined;
}

export type CreateOrganisationProps = Omit<OrganisationProps, 'id'> & { address: string | undefined};
export type UpdateOrganisationProps = Omit<OrganisationProps, 'contactsId'>;
export type OrganisationInEnergyContractProps = Omit<OrganisationProps, 'contacts' | 'projects'>;
export type OrganisationInMaintenanceContractProps = Omit<OrganisationProps, 'contacts' | 'projects'>;
export type OrganisationInIndividualCostHeatingContractProps = Omit<OrganisationProps, 'contacts' | 'projects'>;
export type OrganisationWithoutOrganisationInContactsProps =
OrganisationProps & { contacts: Omit<ContactProps, 'organisation' | 'projects'>[] | undefined };
export type SearchOrganisationsProps = Pick<OrganisationProps, 'socialReason' | 'type' | 'id' | 'organisationType'>;
export type PaginateOrganisationProps = PaginateProps & Partial<SortProps<OrganisationProps>> & Partial<SearchProps> & {
  types?: EType[];
}
