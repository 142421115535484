import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Menu, MenuItem, Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { getUser } from '../../../libs/auth.service';
import { ETeam, UserProps } from '../../../modules/user/domain/user.entity';
import { Colors } from '../../utils/colors';
import { UserModal } from '../modal/UserModal';
import { ModalContact } from '../modal/addContact';
import { ModalOrganisation } from '../modal/addOrganisation';
import { ModalProject } from '../modal/addProject';
import GenericButton from '../shared/Buttons/GenericButton';

export function MenuSelectModal() {
  const user: UserProps = getUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [project, setProject] = useState(false);
  const [contact, setContact] = useState(false);
  const [organisation, setOrganisation] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProject = () => {
    setAnchorEl(null);
    setProject(true);
  };

  const handleContact = () => {
    setAnchorEl(null);
    setContact(true);
  };

  const handleOrganisation = () => {
    setAnchorEl(null);
    setOrganisation(true);
  };

  const handleUser = () => {
    setAnchorEl(null);
    setCreateUser(true);
  };

  return (
    <div>
      <Tooltip title="Ajoutez un projet, un contact ou une organisation">
        <span>
          <GenericButton
            icon={<AddCircleOutlineIcon sx={{ fill: Colors.white }} />}
            onClick={handleClick}
            disableElevation
            variant="contained"
            size="small"
            sx={{
              color: 'white',
              fontWeight: 'normal',
              fontSize: '12px',
            }}
          />
        </span>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleProject}>
          Projet
        </MenuItem>
        <MenuItem onClick={handleContact}>Contact</MenuItem>
        <MenuItem onClick={handleOrganisation}>Organisation</MenuItem>
        {user?.team?.includes(ETeam.SUPER_ADMIN) ? (
          <MenuItem onClick={handleUser}>Utilisateur</MenuItem>
        ) : null}
      </Menu>
      {project ? (
        <ModalProject
          open={project}
          setOpen={setProject}
          closeModal={setProject}
        />
      ) : null}
      {contact ? (
        <ModalContact
          open={contact}
          setOpen={setContact}
          isRedirect
        />
      ) : null}
      {organisation ? (
        <ModalOrganisation
          open={organisation}
          setOpen={setOrganisation}
          isRedirect
        />
      ) : null}
      {createUser ? (
        <UserModal
          open={createUser}
          setOpen={setCreateUser}
          isRedirect
        />
      ) : null}
    </div>
  );
}
