/* eslint-disable react/jsx-props-no-spreading */
import { Dispatch, SetStateAction } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertMessage } from '../../../../libs/alertMessage';
import { handleUseCaseError } from '../../../../libs/ddd/errors/HandleUseCaseError';
import {
  ContactProps, CreateContactProps, ListCivility,
} from '../../../../modules/contact/domain/contact.entity';
import { useContactCreate } from '../../../../modules/contact/queries/useContacts';
import { SelectOrganisation } from '../../Form/InputSelectSearchAsync/SelectOrganisation';
import { SelectProject } from '../../Form/InputSelectSearchAsync/SelectProject';
import { InputText } from '../../Form/InputText';
import { RadioLoop } from '../../Form/Radio';
import { TextArea } from '../../Form/TextArea';
import SaveButton from '../../shared/Buttons/SaveButton';
import { GenericModal } from '../../shared/Modals/GenericModal/index';
import { DivContainer, Wrapper } from '../style';
import { VerticalL } from '../verticalLine';
import { useFormContactHook, useToggleModal } from './hooks';

// To Check the multipleEnums and the Project asyn select
export interface ModalContactProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isRedirect?: boolean;
}
export function ModalContact({
  open, setOpen, isRedirect,
}: ModalContactProps) {
  const navigate = useNavigate();
  const methods = useFormContactHook();
  useToggleModal(setOpen, open);

  const addContact = useContactCreate();
  const onSubmit = (values: CreateContactProps) => {
    addContact.mutateAsync(values)
      .then(({ id }) => {
        setOpen(false);
        if (isRedirect) {
          navigate(`/contact/${id}`);
        }
      })
      .catch((error) => alertMessage.error(handleUseCaseError(error)));
  };
  const email = methods.watch('email');
  const phone = methods.watch('phone');
  const user = methods.watch('user');
  return (
    <GenericModal
      title="Contact"
      open={open}
      setOpen={setOpen}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Wrapper>
            <DivContainer>
              <RadioLoop<CreateContactProps>
                name="civility"
                choices={ListCivility}
              />
              <InputText<CreateContactProps>
                name="email"
                label="Email"
                disabled={Boolean(user)}
                rules={{
                  validate: (value) => {
                    if (!value && !phone) {
                      return 'Email ou téléphone requis';
                    }
                    if (user) {
                      return 'Cet email est lié à un utilisateur';
                    }
                    return true;
                  },
                  pattern: {
                    value: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: 'Adresse email invalide',
                  },
                }}

              />
              <InputText<CreateContactProps>
                name="firstname"
                label="Prénom"
              />
              <InputText<CreateContactProps>
                name="lastname"
                label="Nom"
              />
              <InputText<CreateContactProps>
                name="phone"
                label="N° de téléphone"
                rules={{
                  validate: (value) => (value || email ? true : 'Email ou téléphone requis'),
                  pattern: {
                    value: /[0-9]/,
                    message: 'Doit contenir seulement des chiffres',
                  },
                  minLength: {
                    value: 10,
                    message: 'Format invalide',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Format invalide',
                  },
                }}
              />
            </DivContainer>
            <VerticalL />
            <DivContainer>
              <SelectProject<ContactProps>
                label="Projet(s) lié(s)"
                name="projects"
                multiple
              />
              <SelectOrganisation<ContactProps>
                label="Organisation liée"
                name="organisation"
              />
              {' '}
              <TextArea<CreateContactProps>
                name="comment"
                label="Commentaires"
              />

            </DivContainer>
          </Wrapper>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <SaveButton />
          </div>
        </form>
      </FormProvider>
    </GenericModal>
  );
}

ModalContact.defaultProps = {
  isRedirect: false,
};
