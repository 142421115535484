/* eslint-disable react/jsx-props-no-spreading */
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Badge,
  IconButton, List, Tooltip,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo.png';
import { ERoutePath } from '../../../interface-adaptaters/interfaces/router/route.interface';
import { logoutStorage } from '../../../libs/auth.service';
import { useUserFindMe } from '../../../modules/user/queries/useUser';
import { ESubscriptionType } from '../../../modules/workspace/domain/workspace.entity';
import { Colors } from '../../utils/colors';
import { walterreTheme } from '../../utils/theme';
import { MenuSelectModal } from '../MenuSelectModal';
import { SearchSelect } from '../SearchSelect';
import { SearchBar } from '../inputs/SearchBar/index';
import NavBarLinks from '../shared/Links/NavButton';
import { UserMenu } from './UserMenu';
import { LogoStyle, StyledLink, WaltappLinkStyled } from './style';

function NavigationBar() {
  const { data } = useUserFindMe();

  return (
    <AppBar position="sticky" style={{ backgroundColor: 'white' }}>
      <ThemeProvider theme={walterreTheme}>
        <Container
          maxWidth="xl"
          sx={{
            padding: { xs: '8px 10px', sm: '8px 24px', md: '8px 16px' },
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: { xs: 'none', md: 'space-between' },
              rowGap: { xs: '10px', md: 'none' },
            }}
          >

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: { xs: '100%', md: 'auto' },
                rowGap: '10px',
                justifyContent: { xs: 'space-between', md: 'flex-start' },
              }}
            >
              <StyledLink to={ERoutePath.HOME}>
                <LogoStyle src={data?.workspace.logo?.url ?? logo} alt="Logo" />
              </StyledLink>
              <WaltappLinkStyled
                to={process.env.REACT_APP_PWA_URL as string}
                target="_blank"
                rel="noopener noreferrer"
              >
                Application terrain
              </WaltappLinkStyled>
              <IconButton
                LinkComponent={Link}
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  alignItems: 'center',
                }}
                href={ERoutePath.LOGIN}
                onClick={() => logoutStorage()}
              >
                <LogoutIcon />
              </IconButton>
            </Box>

            <List sx={{ display: { xs: 'none', md: 'flex' }, columnGap: '4px' }}>
              <NavBarLinks to={ERoutePath.HOME} label="Accueil" />
              <NavBarLinks to={ERoutePath.CONTACTS} label="Contacts" />
              <NavBarLinks to={ERoutePath.PROJECTS} label="Projets" />
              <NavBarLinks to={ERoutePath.ORGANISATIONS} label="Organisations" />
            </List>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SearchSelect />
              <SearchBar />
              <MenuSelectModal />
            </Box>

            {data?.workspace.subscriptionType === ESubscriptionType.DEMO ? (
              <Tooltip
                title={`Votre période d’essai prendra fin le ${dayjs(data?.workspace?.expirationDate).format('DD MMMM YYYY')},
            si vous souhaitez continuer de bénéficier de votre espace WaltApp, contactez-nous dans le chat ou par mail à contact@waltapp.io`}
                placement="bottom"
                arrow
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 20],
                        },
                      },
                    ],
                  },
                }}
              >
                <Badge
                  badgeContent="Démo"
                  sx={{
                    '& .MuiBadge-badge': {
                      fontSize: '0.8rem',
                      fontWeight: 600,
                      height: '20px',
                      minWidth: '45px',
                      padding: '15px 14px',
                      border: `3px solid ${Colors.orange}`,
                      color: Colors.orange,
                      // transform: 'translate(35%, -60%) rotate3d(-1, -1, -1, 23deg)',
                    },
                  }}
                />
              </Tooltip>

            ) : null}

            <UserMenu />

          </Toolbar>
        </Container>
      </ThemeProvider>
    </AppBar>
  );
}
export default NavigationBar;
