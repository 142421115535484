import {
  Table, TableBody, TableContainer, TableRow,
} from '@mui/material';
import { isArray } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../hooks/useTranslation';
import { ERoutePath } from '../../../interface-adaptaters/interfaces/router/route.interface';
import { ENameSpaceTranslation } from '../../../libs/i18next';
import { useAppSelector } from '../../../libs/store';
import { SearchContactsResponse } from '../../../modules/contact/responses/SearchContacts.response.dto';
import { EOrganisationType, EType } from '../../../modules/organisation/domain/organisation.entity';
import { SearchOrganisationsResponse } from '../../../modules/organisation/responses/SearchOrganisations.response.dto';
import { SearchProjectsResponse } from '../../../modules/project/responses/Search-project.response';
import { ListItem } from '../List/ListItem';
import { ListContainerStyle } from './style';

export function SearchList({
  contactsSearch,
  organisationsSearch,
  projectsSearch,
  setClean,
}: {
  contactsSearch?: SearchContactsResponse[],
  organisationsSearch?: SearchOrganisationsResponse[],
  projectsSearch?: SearchProjectsResponse[],
  setClean: Dispatch<SetStateAction<boolean>>,
}) {
  const { show } = useAppSelector(({ search }) => search);
  const { t } = useTranslation();
  const getOrganisationType = (organisationType: EOrganisationType | undefined, type: EType | undefined) => {
    if (!organisationType) {
      return '';
    }
    if (organisationType === EOrganisationType.OTHER || !type) {
      return `${t(organisationType, [ENameSpaceTranslation.ORGANISATION])}`;
    }
    return `${t(organisationType, [ENameSpaceTranslation.ORGANISATION])} - ${t(type, [ENameSpaceTranslation.COMPANY])}`;
  };

  if (show && isArray(contactsSearch) && contactsSearch.length > 0) {
    return (
      <TableContainer
        style={ListContainerStyle}
      >
        <Table>
          <TableBody>
            {contactsSearch.map((contact) => (
              <Link
                to={ERoutePath.CONTACT.replace(':id', contact.id.toString())}
                onClick={() => setClean(true)}
                style={{
                  display: 'contents',
                }}
              >
                <TableRow hover tabIndex={-1} key={contact.id}>
                  <ListItem
                    value={contact.firstname ?? '-'}
                    minWidth={0}
                  />
                  <ListItem
                    value={contact.lastname ?? '-'}
                    minWidth={0}
                  />
                  <ListItem
                    value={contact.email ?? '-'}
                    minWidth={0}
                  />
                </TableRow>
              </Link>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    );
  } if (show && isArray(projectsSearch) && projectsSearch.length > 0) {
    return (
      <TableContainer
        style={ListContainerStyle}
      >
        <Table>
          <TableBody>
            {projectsSearch.map((project) => (
              <Link
                to={ERoutePath.PROJECT.replace(':id', project.id.toString())}
                onClick={() => setClean(true)}
                style={{
                  display: 'contents',
                }}
              >
                <TableRow hover tabIndex={-1} key={project.id}>
                  <ListItem
                    value={project.name ?? '-'}
                    minWidth={0}
                  />
                  <ListItem
                    value={t(project.phase, [ENameSpaceTranslation.PROJECT])}
                    minWidth={0}
                  />
                  <ListItem
                    value={project.technician?.contact.fullname ?? '-'}
                    minWidth={0}
                  />
                </TableRow>
              </Link>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    );
  } if (show && isArray(organisationsSearch)) {
    return (
      <TableContainer
        style={ListContainerStyle}
      >
        <Table>
          <TableBody>
            {organisationsSearch.map((organisation) => (
              <Link
                to={ERoutePath.ORGANISATION.replace(':id', organisation.id.toString())}
                onClick={() => setClean(true)}
                style={{
                  display: 'contents',
                }}
              >
                <TableRow hover tabIndex={-1} key={organisation.id}>
                  <ListItem
                    value={organisation.socialReason ?? '-'}
                    minWidth={0}
                  />
                  <ListItem
                    value={getOrganisationType(organisation?.organisationType, organisation?.type)}
                    minWidth={0}
                  />
                </TableRow>
              </Link>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    );
  }
  return <p />;
}

SearchList.defaultProps = {
  contactsSearch: [],
  organisationsSearch: [],
  projectsSearch: [],
};
