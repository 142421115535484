import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CreateContactProps, ECivility } from '../../../../modules/contact/domain/contact.entity';

export const useFormContactHook = () => useForm<CreateContactProps>({
  defaultValues: {
    phone: undefined,
    civility: ECivility.MR,
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    organisation: undefined,
    comment: undefined,
  },
});

export const useToggleModal = (setOpen, open) => {
  useEffect(() => {
    if (!open) {
      setOpen(false);
    }
  }, [setOpen, open]);
};
