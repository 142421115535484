import {
  useMutation,
  UseMutationResult,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '../../../hooks/useQuery';
import {
  CountResponse,
  IdResponse,
  PaginateResponse,
} from '../../../libs/ddd/interfaces/base.interface';
import { OptionsQuery } from '../../../libs/interfaces';
import { OrganisationRepository } from '../api/organisation.repository';
import {
  CreateOrganisationProps,
  PaginateOrganisationProps,
  UpdateOrganisationProps,
} from '../domain/organisation.entity';
import { OrganisationResponse } from '../responses/Organisation.response.dto';
import { SearchOrganisationsResponse } from '../responses/SearchOrganisations.response.dto';

export const ORGANISATIONS_LIST = 'organisationsList';
export const ORGANISATION_ONE = 'Organisation';
export const ORGANISATIONS_LIST_SEARCH = 'organisationsListSearch';
export const ORGANISATIONS_COUNT = 'organisationsCount';

export const useOrganisationsListPaginate = ({
  page, limit, order, sort, search, types,
}: PaginateOrganisationProps, options?: OptionsQuery): UseQueryResult<PaginateResponse<OrganisationResponse[]>, unknown> => useQuery(
  [ORGANISATIONS_LIST, search, page, limit, order, sort, types?.join(',') ?? ''],
  () => new OrganisationRepository().paginateOrganisations({
    page: page + 1, limit, order, sort, search, types,
  }),
  {
    ...options,
  },
);

export const useOrganisationOne = ({
  id,
}): UseQueryResult<OrganisationResponse, unknown> => useQuery(
  [ORGANISATION_ONE, id],
  () => new OrganisationRepository().getOneOrganisation(id as number),
);

export const useOrganisationUpdate = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, UpdateOrganisationProps> => useMutation(
  (values: UpdateOrganisationProps) => new OrganisationRepository().updateOrganisation(values.id, values),
  {
    onSuccess,
  },
);

export const useOrganisationsListSearch = ({
  search,
  enabled,
}: {
  search?: string;
  enabled: boolean;
}): UseQueryResult<SearchOrganisationsResponse[], unknown> => useQuery(
  [ORGANISATIONS_LIST_SEARCH, search ?? ''],
  () => new OrganisationRepository().searchOrganisations(search ?? '', enabled),
  {
    refetchOnWindowFocus: false,
  },
);

export const useOrganisationsCount = (): UseQueryResult<CountResponse, unknown> => useQuery(
  [ORGANISATIONS_COUNT],
  () => new OrganisationRepository().countOrganisations(),
);

export const useOrganisationCreate = (): UseMutationResult<
IdResponse,
unknown,
CreateOrganisationProps
> => useMutation((values: CreateOrganisationProps) => new OrganisationRepository().createOrganisation(values));

export const useOrganisationRemove = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, number> => useMutation((id: number) => new OrganisationRepository().removeOrganisation(id), {
  onSuccess,
});
