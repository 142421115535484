/* eslint-disable no-unused-vars */
import { GenericChoices } from '../../../libs/ddd/interfaces/base.interface';
import { PaginateProps, SearchProps, SortProps } from '../../../libs/interfaces';
import { OrganisationProps } from '../../organisation/domain/organisation.entity';
import { ProjectProps } from '../../project/domain/project.entity';
import { UserWithoutContactProps } from '../../user/domain/user.entity';

export enum ECivility {
  MR = 'M',
  MME = 'Mme',
}

export const ListCivility: GenericChoices<ECivility>[] = [
  { value: ECivility.MR, label: 'Mr' },
  { value: ECivility.MME, label: 'Mme' },
];

export interface ContactProps {
  id: number;
  civility: string;
  firstname: string | undefined;
  lastname: string | undefined;
  fullname: string | undefined;
  email: string;
  phone: string | undefined;
  organisation: OrganisationProps | undefined;
  comment: string | undefined;
  projects: ProjectProps[] | undefined;
  user?: UserWithoutContactProps|number;
}

export type CreateContactProps = Omit<ContactProps, 'id' | 'fullname'>;
export type UpdateContactProps = Omit<ContactProps, 'fullname'>;
export type ContactPropsWithoutOrganisation = Omit<ContactProps, 'organisation' | 'projects'>;
export type SearchContactsProps = Pick<ContactProps, 'firstname' | 'lastname' | 'email' | 'id'>;
export type ContactWithoutUserProps = Omit<ContactProps, 'user'>;
export type PaginateContactProps = PaginateProps & Partial<SortProps<ContactProps>> & Partial<SearchProps>
