import { PaginateResponse } from '../../../libs/ddd/interfaces/base.interface';
import { EMethod, IQuery, UseAxios } from '../../../libs/useAxios';
import {
  CreateOrganisationProps,
  OrganisationProps,
  PaginateOrganisationProps, SearchOrganisationsProps, UpdateOrganisationProps,
} from '../domain/organisation.entity';
import { OrganisationResponse } from '../responses/Organisation.response.dto';
import { OrganisationRepositoryPort } from './organisation.repository.port';

export class OrganisationRepository implements OrganisationRepositoryPort {
  async removeOrganisation(id: number): Promise<{ id: number; }> {
    const result = await new UseAxios<{ id: number }, { id: number }>({
      method: EMethod.DELETE,
      url: '/organisation',
      params: id.toString(),
    }).exec();
    return result;
  }

  async createOrganisation(organisation:CreateOrganisationProps): Promise<{ id: number; }> {
    const result = await new UseAxios<CreateOrganisationProps, Pick<OrganisationResponse, 'id'>>({
      method: EMethod.POST,
      url: '/organisation',
      data: organisation,
    }).exec();
    return result;
  }

  async paginateOrganisations({
    page, limit, order, sort, search, types,
  }: PaginateOrganisationProps): Promise<PaginateResponse<OrganisationProps[]>> {
    const query: IQuery[] = [
      { page: page.toString() },
      { limit: limit.toString() },
      { order: order ?? 'createdAt' },
      { sort: sort ?? 'DESC' },
      search ? { search } : {},
    ];
    if (types) {
      query.push({
        type: types.join(','),
      });
    }
    const result = await new UseAxios<{ count: number }, PaginateResponse<OrganisationProps[]>>({
      method: EMethod.GET,
      url: '/organisations',
      query,
    }).exec();
    return result;
  }

  async searchOrganisations(search: string, enabled: boolean): Promise<SearchOrganisationsProps[]> {
    if (!enabled) return [];
    const query: IQuery[] = [
      { page: '1' },
      { limit: '20' },
      { order: 'socialReason' },
      { sort: 'ASC' },
      search ? { search } : {},
    ];
    const result = await new UseAxios<{ count: number }, PaginateResponse<SearchOrganisationsProps[]>>({
      method: EMethod.GET,
      url: '/organisations',
      query,
    }).exec();
    return result.data;
  }

  async countOrganisations(): Promise<{ count: number }> {
    const result = await new UseAxios<void, { count: number }>({
      method: EMethod.GET,
      url: '/organisations/count',
    }).exec();
    return result;
  }

  async getOneOrganisation(id: number): Promise <OrganisationProps> {
    const result = await new UseAxios<{ id: number }, OrganisationProps>({
      method: EMethod.GET,
      url: '/organisation',
      params: id.toString(),
    }).exec();
    return result;
  }

  async updateOrganisation(id: number, organisation: UpdateOrganisationProps): Promise <{ id: number }> {
    const result = await new UseAxios<UpdateOrganisationProps, { id:number }>({
      method: EMethod.PUT,
      url: `/organisation/${id}`,
      data: organisation,
    }).exec();
    return result;
  }
}
