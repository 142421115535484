/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  Navigate, Route, Routes, useLocation,
} from 'react-router-dom';
import useScreenSize from '../hooks/useScreenSize';
import { ERoutePath, IRoute } from '../interface-adaptaters/interfaces/router/route.interface';
import { ETeam, UserProps } from '../modules/user/domain/user.entity';
import NavigationBar from '../ui/components/navigationBar/NavigationBar';
import { Wrapper } from '../ui/components/shared/Wrapper';
import { alertMessage } from './alertMessage';
import { getAccessToken, getUser, logoutStorage } from './auth.service';

const Dashboard = React.lazy(() => import('../ui/pages/Dashboard'));
const Contacts = React.lazy(() => import('../ui/pages/ContactList'));
const Projects = React.lazy(() => import('../ui/pages/ProjectList'));
const Organisations = React.lazy(() => import('../ui/pages/OrganisationList'));
const Organisation = React.lazy(() => import('../ui/pages/Organisation'));
const Contact = React.lazy(() => import('../ui/pages/Contact'));
const Project = React.lazy(() => import('../ui/pages/Project/index'));
const Settings = React.lazy(() => import('../ui/pages/Settings'));
const Profil = React.lazy(() => import('../ui/pages/Profil'));
const Token = React.lazy(() => import('../ui/pages/Token'));
const UserList = React.lazy(() => import('../ui/pages/UsersList'));
const NotFound = React.lazy(() => import('../ui/pages/NotFound'));
const Login = React.lazy(() => import('../ui/pages/Login'));
const ForgotPassword = React.lazy(() => import('../ui/pages/ForgotPassword'));
const ChangePassword = React.lazy(() => import('../ui/pages/ChangePassword'));
const CreatePassword = React.lazy(() => import('../ui/pages/CreatePassword'));
const Recommendation = React.lazy(() => import('../ui/pages/Recommendation'));
const User = React.lazy(() => import('../ui/pages/User/User'));

interface IProtectedRoute {
  children: React.ReactElement;
  permissions: ETeam[] | undefined;
  walterre?: boolean;
}

const ROUTES_LIST: IRoute[] = [{
  path: ERoutePath.HOME,
  component: Dashboard,
  permissions: [ETeam.TECHNICIAN, ETeam.ADMIN, ETeam.SUPER_ADMIN],
}, {
  path: ERoutePath.CONTACTS,
  component: Contacts,
  permissions: [ETeam.TECHNICIAN, ETeam.ADMIN, ETeam.SUPER_ADMIN],
}, {
  path: ERoutePath.PROJECTS,
  component: Projects,
  permissions: [ETeam.TECHNICIAN, ETeam.ADMIN, ETeam.SUPER_ADMIN],
}, {
  path: ERoutePath.PROFIL,
  component: Profil,
  permissions: [ETeam.TECHNICIAN, ETeam.ADMIN, ETeam.SUPER_ADMIN],
}, {
  path: ERoutePath.ORGANISATIONS,
  component: Organisations,
  permissions: [ETeam.TECHNICIAN, ETeam.ADMIN, ETeam.SUPER_ADMIN],
}, {
  path: ERoutePath.SETTINGS,
  component: Settings,
  permissions: [ETeam.SUPER_ADMIN],
}, {
  path: ERoutePath.TOKENS,
  component: Token,
  permissions: [ETeam.SUPER_ADMIN],
  walterre: true,
}, {
  path: ERoutePath.USERS,
  component: UserList,
  permissions: [ETeam.SUPER_ADMIN],
}, {
  path: ERoutePath.USER,
  component: User,
  permissions: [ETeam.SUPER_ADMIN],
}, {
  path: ERoutePath.CONTACT,
  component: Contact,
  permissions: [ETeam.TECHNICIAN, ETeam.ADMIN, ETeam.SUPER_ADMIN],
}, {
  path: ERoutePath.PROJECT,
  component: Project,
  permissions: [ETeam.TECHNICIAN, ETeam.ADMIN, ETeam.SUPER_ADMIN],
}, {
  path: ERoutePath.ORGANISATION,
  component: Organisation,
  permissions: [ETeam.TECHNICIAN, ETeam.ADMIN, ETeam.SUPER_ADMIN],
}, {
  path: ERoutePath.RECOMMENDATIONS,
  component: Recommendation,
  permissions: [ETeam.TECHNICIAN, ETeam.SUPER_ADMIN],
}];

const ROUTES_WITHOUT_HEADER: string[] = [
  ERoutePath.LOGIN,
  ERoutePath.FORGOT_PASSWORD,
  ERoutePath.CHANGE_PASSWORD,
  ERoutePath.CREATE_PASSWORD,
];

function ProtectedRoute({ children, permissions, walterre }: IProtectedRoute) {
  const user: UserProps = getUser();
  const accessToken = getAccessToken();
  if (accessToken && user) {
    if (
      !permissions
      || (user.team.some((role) => permissions.includes(role))
        && (!walterre
          || (walterre
            && user.workspace.id.toString() === process.env.REACT_APP_WORKSPACE_ID_WALTERRE))
      )
    ) {
      return children;
    }
    alertMessage.warning('Accès refusé');
    return <Navigate to="/" replace />;
  }
  alertMessage.warning('Veuillez vous connecter');
  logoutStorage();
  return <Navigate to="/login" replace />;
}

export function Router() {
  const location = useLocation();
  const { width, alert, setAlert } = useScreenSize();

  React.useEffect(() => {
    if (width <= 900 && !alert) {
      setAlert(true);
      alertMessage.warning('Vous consultez une version simplifiée pour smartphone, connectez vous sur ordinateur ou tablette pour accéder à la version complète');
    }
  }, [alert, setAlert, width]);
  return (
    <>
      {!ROUTES_WITHOUT_HEADER.includes(location.pathname) ? (
        <NavigationBar />
      ) : null}
      <Wrapper>
        <Routes>
          {
            ROUTES_LIST.map((route: IRoute) => (
              <Route
                key={route.path}
                path={route.path}
                element={(
                  <ProtectedRoute
                    permissions={route.permissions}
                    walterre={route.walterre}
                  >
                    <route.component />
                  </ProtectedRoute>
                )}
              />
            ))
          }
          <Route
            path={ERoutePath.LOGIN}
            element={<Login />}
          />
          <Route
            path={ERoutePath.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route
            path={ERoutePath.CHANGE_PASSWORD}
            element={<ChangePassword />}
          />
          <Route
            path={ERoutePath.CREATE_PASSWORD}
            element={<CreatePassword />}
          />
          <Route
            path="*"
            element={<NotFound />}
          />
        </Routes>
      </Wrapper>
    </>
  );
}

ProtectedRoute.defaultProps = {
  walterre: false,
};
